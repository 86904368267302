import React from 'react'
import ZonalProduct from "../zonal-product/zonal-product";
import {makeStyles, Typography} from "@material-ui/core";

const useStyles = makeStyles({
    subheader: {
        marginTop: 20,
        marginBottom: 10
    },
    textField: {
        marginBottom: 10,
        fontStyle: 'italic'
    }
})

const ZonalItemList = ({ items, products, choiceGroups }) => {
    const classes = useStyles()

    return items.map((item) => {
        switch (item.itemType) {
            case 'product':
                const product = products.find((p) => p.id === item.product.productId)
                return <ZonalProduct item={item} product={product} choiceProducts={products} choiceGroups={choiceGroups} />
            case 'subHeader':
                return (
                    <Typography className={classes.subheader}>
                        <strong>{item.subHeader.text}</strong>
                    </Typography>
                )
            case 'textField':
                return (
                    <Typography className={classes.textField}>
                        {item.textField.text}
                    </Typography>
                )
            case 'hyperlink':
                return (
                    <pre>
                        {JSON.stringify(item.hyperlink)}
                    </pre>
                )
            case 'image':
                return (
                    <img alt="zonal item" style={{maxHeight: '100px'}} src={item.image.imageURL} />
                )
            default:
                return (
                    <pre>
                        {JSON.stringify(item)}
                    </pre>
                )
        }
    })
}

export default ZonalItemList
