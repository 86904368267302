import {makeStyles, Typography} from "@material-ui/core";
import React, {useState, useEffect} from "react";
import api from "../../../api";
import Paper from "@material-ui/core/Paper";
import {Link, Route, Switch, } from "react-router-dom";

const useStyles = makeStyles({
    rowContainer: {
        width: '100%',
        padding: '4px',
        '& > div': {
            display: 'inline-block'
        },
        '&:hover': {
            background: '#eee'
        }
    },
    orderId: {
        width: '20%'
    },
    venueName: {
        width: '20%'
    },
    lastUpdatedTime: {
        width: '20%'
    },
    userName: {
        width: '20%'
    },
    amount: {
        width: '20%'
    },
})

const OnplDebugger = () => {
    const [outstandingOnplOrders, setOutstandingOnplOrders] = useState(null)
    useEffect(() => {
        const getOutstandingOnplOrders = async () => {
            const orders = await api.getOutstandingOnplOrders()
            setOutstandingOnplOrders(orders)
        }
        getOutstandingOnplOrders()
    }, [])
    const classes = useStyles()

    if (outstandingOnplOrders === null) {
        return null
    }

    return (
        <>
            <Switch>
                <Route path="/onpl">
                <div>
                    <Typography variant="h4">
                        Open ONPL Orders
                    </Typography>

                    {outstandingOnplOrders.length ? (
                        <Paper style={{ height: '100vh', width: '100%' }}>
                            {outstandingOnplOrders.map((outstandingOrder) => {
                                const venueName = outstandingOrder.venueId
                                const lastUpdatedTime = outstandingOrder.updatedAt
                                const users = outstandingOrder.appUsers
                                return (
                                    <Link to={`/order/${outstandingOrder.id}`}>
                                        <div className={classes.rowContainer}>
                                            <div className={classes.orderId}>
                                                <Typography>
                                                    {outstandingOrder.id}
                                                </Typography>
                                            </div>
                                            <div className={classes.venueName}>
                                                <Typography>
                                                    {venueName}
                                                </Typography>
                                            </div>
                                            <div className={classes.lastUpdatedTime}>
                                                <Typography>
                                                    {lastUpdatedTime}
                                                </Typography>
                                            </div>
                                            <div className={classes.userName}>
                                                <Typography>
                                                    {users}
                                                </Typography>
                                            </div>
                                            <div className={classes.amount}>
                                                <Typography>
                                                    {outstandingOrder.total}
                                                </Typography>
                                            </div>
                                        </div>
                                    </Link>
                                )
                            })}
                        </Paper>
                    ) : (
                        <Typography>
                            There are no outstanding ONPL Orders.
                        </Typography>
                    )}
                </div>
                </Route>
            </Switch>
        </>
    )
}

export default OnplDebugger
