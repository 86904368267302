import React from 'react'
import { Switch, Route } from 'react-router-dom'
import IntegrationLinkPanel from '../../integration-link-panel/integration-link-panel'
import OmnivoreLogo from '../../../assets/omnivore-logo.png'

const IntegrationsPage = () => {
    return (
        <>
            <Switch>
                <Route path="/integrations">
                    <div>
                        <IntegrationLinkPanel
                            title="Omnivore"
                            link="https://panel.omnivore.io/"
                            logo={OmnivoreLogo}
                        />

                        <IntegrationLinkPanel
                            title="Omnivore"
                            link="https://panel.omnivore.io/"
                            logo={OmnivoreLogo}
                        />

                        <IntegrationLinkPanel
                            title="Omnivore"
                            link="https://panel.omnivore.io/"
                            logo={OmnivoreLogo}
                        />
                    </div>
                </Route>
            </Switch>
        </>
    )
}

export default IntegrationsPage
