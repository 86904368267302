import React from 'react'
import {makeStyles, Typography} from "@material-ui/core";


const useStyles = makeStyles({
    panel: {
        width: 'calc(100%/3 - 22px)',
        margin: '10px',
        height: 260,
        background: '#fafafa',
        border: '1px solid #eaeaea',
        display: 'inline-block',
        textAlign: 'center',
        padding: '20px 0',
    },
    logo: {
        maxWidth: 300,
        maxHeight: 300,
    }
})

const IntegrationLinkPanel = (props) => {
    const classes = useStyles()

    return (
        <a href={props.link} target="_blank">
            <div className={classes.panel}>
                <img src={props.logo} alt="Logo" className={classes.logo} />
            </div>
        </a>
    )
}

export default IntegrationLinkPanel
