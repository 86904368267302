import React, { useEffect, useState } from 'react';
import {
    Typography,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    makeStyles,
} from '@material-ui/core'
import { ExpandMore } from '@material-ui/icons'
import api from '../../api'
import ZonalItemList from '../zonal-item-list/zonal-item-list'

const useStyles = makeStyles(({
    accordionDetails: {
        display: 'block'
    }
}))

const ZonalMenuDetails = ({ venue, menu }) => {
    const classes = useStyles()
    const [menuResponse, setMenuResponse] = useState(null)

    useEffect(() => {
        const getMenus = async () => {
            setMenuResponse(await api.getMenu(venue.orderPayId, menu.id))
        }
        if (venue && menu) {
            getMenus()
        }
    }, [venue, menu])

    if (!venue || !menu || !menuResponse) {
        return null
    }

    if ((!menuResponse.display) || (!menuResponse.display.displayGroups) || (!menuResponse.display.displayGroups.length)) {
        return (
            <div>
                <Typography>Unable to load menu. (Display or DisplayGroups missing/empty)</Typography>
            </div>
        )
    }

    return (
        <div>
            {menuResponse.display.displayGroups.map((dg) => {
                return (
                    <Accordion key={dg.groupId}>
                        <AccordionSummary expandIcon={<ExpandMore />}>
                            <Typography>{dg.groupName} ({dg.groupId})</Typography>
                        </AccordionSummary>
                        <AccordionDetails className={classes.accordionDetails}>
                            <ZonalItemList
                                choiceGroups={menuResponse.aztec.choiceGroups}
                                products={menuResponse.aztec.products}
                                items={dg.items}
                            />
                        </AccordionDetails>
                    </Accordion>
                )
            })}
        </div>
    );
}

export default ZonalMenuDetails
