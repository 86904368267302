import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import {Accordion, AccordionDetails, AccordionSummary, makeStyles, Typography} from '@material-ui/core'
import api from '../../../api'
import ZonalMenus from "../../zonal-menus/zonal-menus";
import {ExpandMore} from "@material-ui/icons";

const useStyles = makeStyles({
    accordionDetails: {
        display: 'block',
        position: 'relative'
    },
    copyLink: {
        cursor: 'copy',
        display: 'block',
        position: 'absolute',
        top: 8,
        right: 18,
        color: '#22f'
    },
    rootAccordion: {
        width: '100%'
    },
    rawResponseDisplay: {
        maxHeight: '80vh',
        overflow: 'auto',
        border: '1px solid #000',
        background: '#dedede',
        marginBottom: 30,
        width: '100%',
        color: '#1a1a1a'
    },
    topVenueDataContainer: {
        display: 'inline-block',
        width: 'calc(20% - 20px)',
        padding: 10,
        marginBottom: 30,
        verticalAlign: 'top'
    },
    topVenueDataLabel: {
        display: 'block'
    }
})

const ZonalVenueOverview = ({ venues }) => {
    let { venueId } = useParams();
    const [zonalVenueResponse, setZonalVenueResponse] = useState(null)
    const [zonalVenueSummaryResponse, setZonalVenueSummaryResponse] = useState(null)

    const classes = useStyles()

    const venue = venues.find((v) => v.orderPayId === venueId)

    useEffect(() => {
        const getVenueFromZonal = async () => {
            setZonalVenueResponse(await api.getVenueFromZonal(venueId))
        }
        getVenueFromZonal()
    }, [venueId])

    useEffect(() => {
        const getVenueSummaryFromZonal = async () => {
            setZonalVenueSummaryResponse(await api.getVenueSummaryFromZonal(venueId))
        }
        getVenueSummaryFromZonal()
    }, [venueId])

    if (!venue) {
        return null
    }

    return (
        <div>
            <Typography variant="h3">
                {venue.title}
            </Typography>

            <Typography className={classes.topVenueDataContainer}>
                <span className={classes.topVenueDataLabel}>Zonal ID</span> {venue.zonalId}
            </Typography>
            <Typography className={classes.topVenueDataContainer}>
                <span className={classes.topVenueDataLabel}>Sync In Progress?</span> {!!venue.syncStartedAt ? 'Y': 'N'}
            </Typography>
            <Typography className={classes.topVenueDataContainer}>
                <span className={classes.topVenueDataLabel}>Previous Sync Started</span> {venue.lastSyncStartedAt}
            </Typography>
            <Typography className={classes.topVenueDataContainer}>
                <span className={classes.topVenueDataLabel}>Previous Sync Finished</span> {venue.lastSyncFinishedAt}
            </Typography>
            <Typography className={classes.topVenueDataContainer}>
                <span className={classes.topVenueDataLabel}>Previous Sync Result</span> {venue.syncStatus || 'Success'}
            </Typography>

            <Accordion className={classes.rootAccordion}>
                <AccordionSummary expandIcon={<ExpandMore />}>
                    <Typography>GetVenue response</Typography>
                </AccordionSummary>
                <AccordionDetails className={classes.accordionDetails}>
                    <Typography className={classes.copyLink} onClick={( ) => {
                        navigator.clipboard.writeText(JSON.stringify(zonalVenueResponse, null, 2))
                    }}>[Copy]</Typography>
                    <div className={classes.rawResponseDisplay}>
                        <pre>
                            {JSON.stringify(zonalVenueResponse, null, 2)}
                        </pre>
                    </div>
                </AccordionDetails>
            </Accordion>

            <Accordion className={classes.rootAccordion}>
                <AccordionSummary expandIcon={<ExpandMore />}>
                    <Typography>GetVenueSummary response</Typography>
                </AccordionSummary>
                <AccordionDetails className={classes.accordionDetails}>
                    <Typography className={classes.copyLink} onClick={( ) => {
                        navigator.clipboard.writeText(JSON.stringify(zonalVenueSummaryResponse, null, 2))
                    }}>[Copy]</Typography>
                    <div className={classes.rawResponseDisplay}>
                        <pre>
                            {JSON.stringify(zonalVenueSummaryResponse, null, 2)}
                        </pre>
                    </div>
                </AccordionDetails>
            </Accordion>

            <ZonalMenus venue={venue} />
        </div>
    )
}

export default ZonalVenueOverview
