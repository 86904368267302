import React, { useState, useEffect } from 'react'
import { Switch, Route } from 'react-router-dom'
import {
    TextField,
    InputAdornment,
    makeStyles,
    FormControlLabel,
    Switch as MUISwitch,
    Typography
} from "@material-ui/core";
import { SearchOutlined } from "@material-ui/icons";
import OrderPayVenueList from '../../orderpay-venue-list/orderpay-venue-list-virtualized'
import api from '../../../api'
import OrderpayVenueListStats from "../../orderpay-venue-list-stats/zonal-venue-list-stats";

const useStyles = makeStyles({
    searchContainer: {
        margin: 10,
        width: '100%',
    },
    searchOption: {
        display: 'inline-block',
        margin: '0 40px',
    },
    matchCount: {
        margin: 40,
        fontWeight: 'bold'
    }
})

const OrderpayDebugger = () => {
    const [venues, setVenues] = useState([])
    const [venueProductCount, setVenueProductCount] = useState([])
    const [filteredVenues, setFilteredVenues] = useState([])

    const [nameSearchCriteria, setNameSearchCriteria] = useState('')
    const [isShowingZeroProductVenues, setIsShowingZeroProductVenues] = useState(false)
    const [isShowingZeroMenuVenues, setIsShowingZeroMenuVenues] = useState(false)
    const [isShowingPublishedVenues, setIsShowingPublishedVenues] = useState(true)

    useEffect(() => {
        const getVenueList = async () => {
            const venues = await api.getVenueList()
            setVenues(venues)
            setFilteredVenues(venues)
        }
        const getVenueListProductCount = async () => {
            const venuesProductCount = await api.getVenueListProductCount()
            setVenueProductCount(venuesProductCount)
        }
        getVenueList()
        getVenueListProductCount()
    }, [])

    useEffect(() => {
        let newVenues = venues
        if (nameSearchCriteria) {
            newVenues = newVenues.filter((v) => {
                const venueTitle = v.name.toUpperCase();
                return venueTitle.includes(nameSearchCriteria)
            })
        }
        if (isShowingZeroMenuVenues) {
            newVenues = newVenues.filter((v) => {
                const productCountRecord = venueProductCount.find((pCR) => pCR.venueId === v.id)
                return !productCountRecord
            })
        }
        if (isShowingZeroProductVenues) {
            newVenues = newVenues.filter((v) => {
                const productCountRecord = venueProductCount.find((pCR) => pCR.venueId === v.id)
                return productCountRecord && productCountRecord.product_count === "0"
            })
        }
        if (isShowingPublishedVenues) {
            newVenues = newVenues.filter((v) => v.isPublished)
        }
        setFilteredVenues(newVenues)
    }, [venues, nameSearchCriteria, isShowingZeroProductVenues, isShowingZeroMenuVenues, venueProductCount, isShowingPublishedVenues])

    const classes = useStyles()

    return (
        <>
            <Switch>
                <Route path="/">
                    <>
                        <OrderpayVenueListStats venues={venues} venueProductCount={venueProductCount} />
                        <div className={classes.searchContainer}>
                            <TextField
                                label="Venue Name Search"
                                className={classes.searchOption}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <SearchOutlined />
                                        </InputAdornment>
                                    )
                                }}
                                onChange={(e) => {
                                    const value = e.target.value.toUpperCase()
                                    setNameSearchCriteria(value)
                                }}
                            />

                            <FormControlLabel
                                className={classes.searchOption}
                                control={
                                    <MUISwitch
                                        color="primary"
                                        onChange={(evt) => {
                                            setIsShowingZeroProductVenues(evt.target.checked)
                                        }}
                                        disabled={isShowingZeroMenuVenues}/>
                                }
                                label="Only show 0 product venues"
                            />

                            <FormControlLabel
                                className={classes.searchOption}
                                control={
                                    <MUISwitch
                                        color="primary"
                                        onChange={(evt) => {
                                            setIsShowingZeroMenuVenues(evt.target.checked)
                                        }}
                                        disabled={isShowingZeroProductVenues}/>
                                }
                                label="Only show 0 menus venues"
                            />

                            <FormControlLabel
                                className={classes.searchOption}
                                checked={isShowingPublishedVenues}
                                control={
                                    <MUISwitch
                                        color="primary"
                                        onChange={(evt) => {
                                            setIsShowingPublishedVenues(evt.target.checked)
                                        }}
                                    />
                                }
                                label="Only show published venues"
                            />
                        </div>
                        <Typography className={classes.matchCount}>{filteredVenues.length} matches</Typography>
                        <OrderPayVenueList venues={filteredVenues} venueProductCount={venueProductCount} />
                    </>
                </Route>
            </Switch>
        </>
    )
}

export default OrderpayDebugger

