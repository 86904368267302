import React from 'react'
import {Typography} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles({
    statsContainer: {
        padding: 30,

        '& > p': {
            display: 'inline-block',
            margin: '0 100px 0 0'
        }
    }
})

const ZonalVenueListStats = ({ venues }) => {
    const classes = useStyles()

    return (
        <div className={classes.statsContainer}>
            <Typography>
                Venue Count: {venues.length}
            </Typography>

            <Typography>
                Syncs in progress: {venues.filter((v) => v.syncStartedAt !== null).length}
            </Typography>

            <Typography>
                Previous sync failed: {venues.filter((v) => v.syncStatus !== null).length}
            </Typography>
        </div>
    );
}

export default ZonalVenueListStats
