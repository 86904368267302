import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import { withStyles } from '@material-ui/core/styles'
import TableCell from '@material-ui/core/TableCell'
import Paper from '@material-ui/core/Paper'
import { AutoSizer, Column, Table } from 'react-virtualized'
import api from "../../../api";
import {useLocation, useParams} from "react-router-dom";
import {Typography} from "@material-ui/core";
import RecommendationView from "../../recommendation-view/recommendation-view";

const styles = (theme) => ({
    flexContainer: {
        display: 'flex',
        alignItems: 'center',
        boxSizing: 'border-box',
        width: '100%'
    },
    tableRow: {
        cursor: 'pointer',
    },
    tableRowHover: {
        '&:hover': {
            backgroundColor: theme.palette.grey[200],
        },
    },
    headerCell: {
        width: '100%',
        background: '#0063c0',
        color: '#fff'
    },
});

class MuiVirtualizedTable extends React.PureComponent {
    static defaultProps = {
        headerHeight: 48,
        rowHeight: 48,
    };

    getRowClassName = ({ index }) => {
        const { classes, onRowClick } = this.props;

        return clsx(classes.tableRow, classes.flexContainer, {
            [classes.tableRowHover]: index !== -1 && onRowClick != null,
        });
    };

    cellRenderer = ({ cellData, columnIndex }) => {
        const { classes, rowHeight } = this.props;
        return (
            <TableCell
                component="div"
                className={clsx(classes.flexContainer, `venue-cell-${columnIndex+1}`)}
                variant="body"
                style={{ height: rowHeight }}
            >
                {cellData}
            </TableCell>
        );
    };

    headerRenderer = ({ label }) => {
        const { headerHeight, classes } = this.props;

        return (
            <TableCell
                component="div"
                className={clsx(classes.flexContainer, classes.headerCell)}
                variant="head"
                style={{ height: headerHeight }}
            >
                <span>{label}</span>
            </TableCell>
        );
    };

    render() {
        const { classes, columns, rowHeight, headerHeight, ...tableProps } = this.props;
        return (
            <AutoSizer>
                {({ height, width }) => (
                    <Table
                        headerClassName="orderpay-venue-table"
                        gridClassName="orderpay-venue-table"
                        height={height}
                        width={width}
                        rowHeight={rowHeight}
                        gridStyle={{
                            direction: 'inherit',
                        }}
                        headerHeight={headerHeight}
                        className={classes.table}
                        {...tableProps}
                        rowClassName={this.getRowClassName}
                    >
                        {columns.map(({ dataKey, ...other }, index) => {
                            return (
                                <Column
                                    key={dataKey}
                                    headerRenderer={(headerProps) =>
                                        this.headerRenderer({
                                            ...headerProps,
                                            columnIndex: index,
                                        })
                                    }
                                    className={classes.flexContainer}
                                    cellRenderer={this.cellRenderer}
                                    dataKey={dataKey}
                                    {...other}
                                />
                            );
                        })}
                    </Table>
                )}
            </AutoSizer>
        );
    }
}

MuiVirtualizedTable.propTypes = {
    classes: PropTypes.object.isRequired,
    columns: PropTypes.arrayOf(
        PropTypes.shape({
            dataKey: PropTypes.string.isRequired,
            label: PropTypes.string.isRequired,
            numeric: PropTypes.bool,
            width: PropTypes.number.isRequired,
        }),
    ).isRequired,
    headerHeight: PropTypes.number,
    onRowClick: PropTypes.func,
    rowHeight: PropTypes.number,
};

const VirtualizedTable = withStyles(styles)(MuiVirtualizedTable);

const ReactVirtualizedTable = () => {
    const { venueId } = useParams();
    const { search } = useLocation()
    const numberOfDaysToConsiderForRecs = new URLSearchParams(search).get('d')

    const [menus, setMenus] = useState([])
    const [venue, setVenue] = useState(null)
    const [activeProduct, setActiveProduct] = useState(null)

    useEffect(() => {
        const fetchMenus = async () => {
            setMenus(await api.getOrderPayMenus(venueId))
        }
        fetchMenus()
    }, [venueId])

    useEffect(() => {
        const fetchVenue = async () => {
            setVenue(await api.getVenue(venueId))
        }
        fetchVenue()
    }, [venueId])

    return (
        <Paper style={{ height: '100vh', width: '100%' }}>

            {activeProduct && (
                <RecommendationView
                    productId={activeProduct.productId}
                    productName={activeProduct.productName}
                    numberOfDaysToConsiderForRecs={numberOfDaysToConsiderForRecs}
                    close={() => { setActiveProduct(null) }}
                />
            )}

            <Typography
                variant="h4"
                style={{
                    margin: '10px 0',
                }}
            >
                {venue?.name}
            </Typography>
            <Typography>
                Select a product to view the most ordered-with items...
            </Typography>

            <VirtualizedTable
                rowCount={menus.length}
                onRowClick={({ rowData }) => {
                    setActiveProduct(rowData)
                }}
                rowGetter={({ index }) => menus[index]}
                columns={[
                    { label: 'Menu', dataKey: 'menuTitle' },
                    { label: 'Product', dataKey: 'productName' },
                ]}
            />
        </Paper>
    );
}

export default ReactVirtualizedTable
