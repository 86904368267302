import React, { useState, useEffect } from 'react'
import {makeStyles, Typography} from '@material-ui/core'
import api from '../../api'
import ZonalMenusList from '../zonal-menus-list/zonal-menus-list'

const useStyles = makeStyles({
    menuContainer: {
        marginTop: 30
    }
})

const ZonalMenus = ({ venue }) => {
    const classes = useStyles()
    const [menusResponse, setMenusResponse] = useState([])

    useEffect(() => {
        const getMenus = async () => {
            setMenusResponse(await api.getMenus(venue.orderPayId))
        }
        if (venue.orderPayId) {
            getMenus()
        }
    }, [venue.orderPayId])

    if (!venue) {
        return null
    }

    const hasMenus = menusResponse && menusResponse.menus && menusResponse.menus.length

    return (
        <div className={classes.menuContainer}>
            <Typography variant="h6">
                Menu Response
            </Typography>

            {!hasMenus && (
                <div>
                    <Typography>
                        Unable to fetch menus.
                    </Typography>

                    <div style={{ color: '#999' }}>
                        <pre>
                            {JSON.stringify(menusResponse, null, 2)}
                        </pre>
                    </div>
                </div>
            )}

            {hasMenus ? <ZonalMenusList venue={venue} menus={menusResponse.menus} /> : null}
        </div>
    )
}

export default ZonalMenus
