import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import { withStyles } from '@material-ui/core/styles'
import TableCell from '@material-ui/core/TableCell'
import Paper from '@material-ui/core/Paper'
import { AutoSizer, Column, Table } from 'react-virtualized'
import { useHistory } from 'react-router-dom'

const styles = (theme) => ({
    flexContainer: {
        display: 'flex',
        alignItems: 'center',
        boxSizing: 'border-box',
        width: '100%'
    },
    tableRow: {
        cursor: 'pointer',
    },
    tableRowHover: {
        '&:hover': {
            backgroundColor: theme.palette.grey[200],
        },
    },
    headerCell: {
        width: '100%',
        background: '#0063c0',
        color: '#fff'
    },
});

class MuiVirtualizedTable extends React.PureComponent {
    static defaultProps = {
        headerHeight: 48,
        rowHeight: 48,
    };

    getRowClassName = ({ index }) => {
        const { classes, onRowClick } = this.props;

        return clsx(classes.tableRow, classes.flexContainer, {
            [classes.tableRowHover]: index !== -1 && onRowClick != null,
        });
    };

    cellRenderer = ({ cellData, columnIndex }) => {
        const { classes, rowHeight } = this.props;
        return (
            <TableCell
                component="div"
                className={clsx(classes.flexContainer, `venue-cell-${columnIndex+1}`)}
                variant="body"
                style={{ height: rowHeight }}
            >
                {cellData}
            </TableCell>
        );
    };

    headerRenderer = ({ label }) => {
        const { headerHeight, classes } = this.props;

        return (
            <TableCell
                component="div"
                className={clsx(classes.flexContainer, classes.headerCell)}
                variant="head"
                style={{ height: headerHeight }}
            >
                <span>{label}</span>
            </TableCell>
        );
    };

    render() {
        const { classes, columns, rowHeight, headerHeight, ...tableProps } = this.props;
        return (
            <AutoSizer>
                {({ height, width }) => (
                    <Table
                        headerClassName="orderpay-venue-table"
                        gridClassName="orderpay-venue-table"
                        height={height}
                        width={width}
                        rowHeight={rowHeight}
                        gridStyle={{
                            direction: 'inherit',
                        }}
                        headerHeight={headerHeight}
                        className={classes.table}
                        {...tableProps}
                        rowClassName={this.getRowClassName}
                    >
                        {columns.map(({ dataKey, ...other }, index) => {
                            return (
                                <Column
                                    key={dataKey}
                                    headerRenderer={(headerProps) =>
                                        this.headerRenderer({
                                            ...headerProps,
                                            columnIndex: index,
                                        })
                                    }
                                    className={classes.flexContainer}
                                    cellRenderer={this.cellRenderer}
                                    dataKey={dataKey}
                                    {...other}
                                />
                            );
                        })}
                    </Table>
                )}
            </AutoSizer>
        );
    }
}

MuiVirtualizedTable.propTypes = {
    classes: PropTypes.object.isRequired,
    columns: PropTypes.arrayOf(
        PropTypes.shape({
            dataKey: PropTypes.string.isRequired,
            label: PropTypes.string.isRequired,
            numeric: PropTypes.bool,
            width: PropTypes.number.isRequired,
        }),
    ).isRequired,
    headerHeight: PropTypes.number,
    onRowClick: PropTypes.func,
    rowHeight: PropTypes.number,
};

const VirtualizedTable = withStyles(styles)(MuiVirtualizedTable);

const ReactVirtualizedTable = ({ venues }) => {
    const history = useHistory()

    const rows = venues.map((venue) => {
        return {
            name: venue.name,
            id: venue.id,
        };
    })

    return (
        <Paper style={{ height: '100vh', width: '100%' }}>
            <VirtualizedTable
                rowCount={rows.length}
                onRowClick={({ rowData }) => {
                    history.push(`/re/${rowData.id}/overview`);
                }}
                rowGetter={({ index }) => rows[index]}
                columns={[
                    { label: 'Title', dataKey: 'name' },
                ]}
            />
        </Paper>
    );
}

export default ReactVirtualizedTable
