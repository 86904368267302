import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import {Accordion, AccordionDetails, AccordionSummary, makeStyles, Typography} from '@material-ui/core'
import api from '../../../api'
import ZonalMenus from "../../zonal-menus/zonal-menus";
import {ExpandMore} from "@material-ui/icons";

const useStyles = makeStyles({
    accordionDetails: {
        display: 'block',
        position: 'relative'
    },
    copyLink: {
        cursor: 'copy',
        display: 'block',
        position: 'absolute',
        top: 8,
        right: 18,
        color: '#22f'
    },
    rootAccordion: {
        width: '100%'
    },
    rawResponseDisplay: {
        maxHeight: '80vh',
        overflow: 'auto',
        border: '1px solid #000',
        background: '#dedede',
        marginBottom: 30,
        width: '100%',
        color: '#1a1a1a'
    },
    topVenueDataContainer: {
        display: 'inline-block',
        width: 'calc(20% - 20px)',
        padding: 10,
        marginBottom: 30,
        verticalAlign: 'top'
    },
    topVenueDataLabel: {
        display: 'block'
    }
})

const OrderViewPage = () => {
    let { id: orderId } = useParams();
    const [orderResponse, setOrderResponse] = useState(null)

    const classes = useStyles()

    useEffect(() => {
        const getOrder = async (id) => {
            setOrderResponse(await api.getOrder(id))
        }
        getOrder(orderId)
    }, [orderId])

    if (!orderResponse) {
        return null
    }

    return (
        <div>
            <Typography variant="h3">
                {orderResponse.id}
            </Typography>

            <pre style={{ color: '#000' }}>
                {JSON.stringify(orderResponse, null, 2)}
            </pre>
        </div>
    )
}

export default OrderViewPage
