import React, { useState, useEffect } from 'react'
import {makeStyles, Typography} from "@material-ui/core";
import api from "../../api";

const useStyles = makeStyles({
    base: {
        display: 'block',
        background: '#ddd',
        width: '90%',
        marginLeft: 'calc(5% - 30px)',
        position: 'absolute',
        top: 10,
        height: 'calc(80vh - 70px)',
        zIndex: 100,
        padding: 30
    },
    underlay: {
        display: 'block',
        background: 'rgba(0, 0, 0, 0.8)',
        width: '100%',
        position: 'fixed',
        top: 0,
        height: '100vh',
        zIndex: 95,
    },
    close: {
        position: 'absolute',
        top: 0,
        right: 0,
        padding: '10px 15px',
        background: '#faa670',
        textAlign: 'center',
        cursor: 'pointer'
    },
    reccomendation: {
        '& p': {
            display: 'inline-block',
            margin: '0 10px 5px 0',
            '&:nth-child(1)': {
                width: 220
            },
            '&:nth-child(2)': {
                width: 220
            },
            '&:nth-child(3)': {
                width: 320
            },
            '&:nth-child(4)': {
                width: 400
            },
            '&:nth-child(5)': {
                width: 120
            },
        },
    }
})

const RecommendationView = (props) => {
    const { productId, productName, numberOfDaysToConsiderForRecs } = props

    const classes = useStyles()

    const [recs, setRecs] = useState([])
    useEffect(() => {
        const getRecs = async () => {
            setRecs(await api.getRecommendations(productId, numberOfDaysToConsiderForRecs))
        }
        getRecs()
    }, [productId])

    return (
        <>
            <div className={classes.base}>
                <Typography>{productName}  ({productId})</Typography>
                <div onClick={props.close} className={classes.close}><Typography>X</Typography></div>

                {recs && (recs.recommendations ?? []).length ? (
                    <>
                        <Typography
                            style={{
                            marginBottom: 20
                            }}
                        >
                            Number of orders with this item: {recs.orderCountWithItem}
                        </Typography>

                        <div className={classes.reccomendation}>
                            <Typography>
                                Orders w/ base item + rec.
                            </Typography>
                            <Typography>
                                % of orders w/ item + rec.
                            </Typography>
                            <Typography>
                                Rec. PID
                            </Typography>
                            <Typography>
                                Product name
                            </Typography>
                            <Typography>
                                Product price
                            </Typography>
                        </div>
                        {recs.recommendations.map((rec) => (
                            <div className={classes.reccomendation}>
                                <Typography>
                                    {rec.orderCountWithItemAndRecommendedItem}
                                </Typography>
                                <Typography>
                                    {(rec.percentageOfOrdersContainingBothItems * 100).toFixed(2)}
                                </Typography>
                                <Typography>
                                    {rec.productId}
                                </Typography>
                                <Typography>
                                    {rec.productName}
                                </Typography>
                                <Typography>
                                    {rec.productPrice}
                                </Typography>
                            </div>
                        ))}
                    </>
                ) : (
                    <div>...</div>
                )}
            </div>
            <div className={classes.underlay}></div>
        </>
    )
}

export default RecommendationView
