import React, { useState, useEffect } from 'react'
import { Switch, Route } from 'react-router-dom'
import {TextField, InputAdornment, makeStyles, Switch as MUISwitch, FormControlLabel} from "@material-ui/core";
import { SearchOutlined } from "@material-ui/icons";
import ZonalVenueList from '../zonal-venue-list/zonal-venue-list-virtualized'
import api from '../../../api'
import ZonalVenueListStats from "../../zonal-venue-list-stats/zonal-venue-list-stats";
import ZonalVenueOverviewPage from "../zonal-venue-overview/zonal-venue-overview";
const useStyles = makeStyles({
    searchContainer: {
        margin: 10,
    }
})

const ZonalDebugger = () => {
    const [venues, setVenues] = useState([])
    const [filteredVenues, setFilteredVenues] = useState([])

    const [nameFilter, setNameFilter] = useState('')
    const [zonalIdFilter, setZonalIdFilter] = useState('')
    const [isShowingInSyncOnly, setIsShowingInSyncOnly] = useState(false)
    const [isShowingPreviousFailedOnly, setIsShowingPreviousFailedOnly] = useState(false)

    useEffect(() => {
        const getZonalVenueList = async () => {
            const venues = await api.getZonalVenueList()
            setVenues(venues)
            setFilteredVenues(venues)
        }
        getZonalVenueList()
    }, [])

    useEffect(() => {
        let newVenues = venues
        if (nameFilter) {
            newVenues = newVenues.filter((v) => {
                const venueTitle = v.title.toUpperCase();
                return venueTitle.includes(nameFilter)
            })
        }
        if (zonalIdFilter) {
            newVenues = newVenues.filter((v) => {
                const venueZonalId = v.zonalId.toUpperCase();
                return venueZonalId.startsWith(zonalIdFilter)
            })
        }
        if (isShowingInSyncOnly) {
            newVenues = newVenues.filter((v) => !!v.syncStartedAt)
        }
        if (isShowingPreviousFailedOnly) {
            newVenues = newVenues.filter((v) => !!v.syncStatus)
        }
        setFilteredVenues(newVenues)
    }, [nameFilter, venues, zonalIdFilter, isShowingInSyncOnly, isShowingPreviousFailedOnly])

    const classes = useStyles()

    return (
        <>
            <Switch>
                <Route path="/zonal/:venueId/overview">
                    <ZonalVenueOverviewPage venues={venues} />
                </Route>
                <Route path="/zonal">
                    <>
                        <ZonalVenueListStats venues={venues} />
                        <div className={classes.searchContainer}>
                            <TextField
                                label="Venue Name Search"
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <SearchOutlined />
                                        </InputAdornment>
                                    )
                                }}
                                onChange={(e) => {
                                    const value = e.target.value.toUpperCase()
                                    setNameFilter(value)
                                }}
                            />

                            <TextField
                                style={{ marginLeft: 30 }}
                                label="Zonal ID Starts with"
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <SearchOutlined />
                                        </InputAdornment>
                                    )
                                }}
                                onChange={(e) => {
                                    const value = e.target.value.toUpperCase()
                                    setZonalIdFilter(value)
                                }}
                            />

                            <FormControlLabel
                                className={classes.searchOption}
                                control={
                                    <MUISwitch
                                        color="primary"
                                        onChange={(evt) => {
                                            setIsShowingInSyncOnly(evt.target.checked)
                                        }}
                                    />
                                }
                                label="Only show in-progress syncs"
                            />

                            <FormControlLabel
                                className={classes.searchOption}
                                control={
                                    <MUISwitch
                                        color="primary"
                                        onChange={(evt) => {
                                            setIsShowingPreviousFailedOnly(evt.target.checked)
                                        }}
                                    />
                                }
                                label="Only show prev sync failed"
                            />
                        </div>
                        <ZonalVenueList venues={filteredVenues} />
                    </>
                </Route>
            </Switch>
        </>
    )
}

export default ZonalDebugger
