import React from 'react'
import { Switch, Route } from 'react-router-dom'
import { Typography } from "@material-ui/core";
import OrderViewPage from '../order-view/order-view'

const OrderViewer = () => {
    return (
        <>
            <Switch>
                <Route path="/order/:id">
                    <OrderViewPage />
                </Route>
                <Route path="/order">
                    <div>
                        <Typography>Order Root!</Typography>
                    </div>
                </Route>
            </Switch>
        </>
    )
}

export default OrderViewer
