import React from 'react'
import {Typography} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles({
    statsContainer: {
        padding: 30,

        '& > p': {
            display: 'inline-block',
            margin: '0 100px 0 0'
        }
    }
})

const OrderpayVenueListStats = ({ venues, venueProductCount }) => {
    const classes = useStyles()

    const zeroProductCount = venueProductCount.filter((a) => a.product_count === "0").length

    return (
        <div className={classes.statsContainer}>
            <Typography>
                Venue Count: {venues.length}
            </Typography>

            <Typography>
                Venues with 0 products: {zeroProductCount}
            </Typography>

            <Typography>
                Venues with 0 menus: {venues.length - venueProductCount.length}
            </Typography>
        </div>
    );
}

export default OrderpayVenueListStats
