import fetch from 'isomorphic-fetch'
let BASE_URL = 'http://localhost:3050'
if (process.env.REACT_APP_BUILD_TYPE === 'development') {
    BASE_URL = 'https://service-support-debugger.alpha.orderpay.com'
} else if (process.env.REACT_APP_BUILD_TYPE === 'production') {
    BASE_URL = 'https://service-support-debugger.orderpay.com'
}

const _fetch = async (url) => {
    const response = await fetch(url, {
        headers: {
            Authorization: window.localStorage.getItem('accessToken')
        }
    })
    if (response.status === 401) {
        window.localStorage.removeItem('accessToken')
        window.localStorage.setItem('at_expired', 1)
        window.location.reload()
    }
    return response
}

const api = {
    getVenueFromZonal: async (venueId) => {
        console.log('getVenueFromZonal request started')
        const response = await _fetch(`${BASE_URL}/zonal/get-venue?venueId=${venueId}`)
        if (response.status === 200) {
            const result = await response.json()
            console.log('getVenueFromZonal request success', result)
            return result
        } else {
            console.error('getVenueFromZonal request failed', response)
        }
    },
    getVenueSummaryFromZonal: async (venueId) => {
        console.log('getVenueSummaryFromZonal request started')
        const response = await _fetch(`${BASE_URL}/zonal/get-venue-summary?venueId=${venueId}`)
        if (response.status === 200) {
            const result = await response.json()
            console.log('getVenueSummaryFromZonal request success', result)
            return result
        } else {
            console.error('getVenueSummaryFromZonal request failed', response)
        }
    },
    getVenueList: async () => {
        console.log('getVenueList request started')
        const response = await _fetch(`${BASE_URL}/orderpay/list-venues`)
        if (response.status === 200) {
            const result = await response.json()
            console.log('getVenueList request success', result)
            return result
        } else {
            console.error('getVenueList request failed', response)
        }
        return []
    },
    getVenueListProductCount: async () => {
        console.log('getVenueListProductCount request started')
        const response = await _fetch(`${BASE_URL}/orderpay/list-venues-product-count`)
        if (response.status === 200) {
            const result = await response.json()
            console.log('getVenueListProductCount request success', result)
            return result
        } else {
            console.error('getVenueListProductCount request failed', response)
        }
        return []
    },
    getZonalVenueList: async () => {
        console.log('getZonalVenueList request started')
        const response = await _fetch(`${BASE_URL}/orderpay/zonal/list-venues`)
        if (response.status === 200) {
            const result = await response.json()
            console.log('getZonalVenueList request success', result)
            return result
        } else {
            console.error('getZonalVenueList request failed', response)
        }
        return []
    },
    getMenus: async (venueId) => {
        console.log('getMenus request started')
        const response = await _fetch(`${BASE_URL}/zonal/get-menus?venueId=${venueId}`)
        if (response.status === 200) {
            const result = await response.json()
            console.log('getMenus request success', result)
            return result
        } else {
            console.error('getMenus request failed', response)
        }
        return []
    },
    getOrderPayMenus: async (venueId) => {
        console.log('getOrderPayMenus request started')
        const response = await _fetch(`${BASE_URL}/orderpay/venue/${venueId}/menus`)
        if (response.status === 200) {
            const result = await response.json()
            console.log('getOrderPayMenus request success', result)
            return result
        } else {
            console.error('getOrderPayMenus request failed', response)
        }
        return []
    },
    getMenu: async (venueId, menuId) => {
        console.log('getMenu request started')
        const response = await _fetch(`${BASE_URL}/zonal/get-menu?venueId=${venueId}&menuId=${menuId}`)
        if (response.status === 200) {
            const result = await response.json()
            console.log('getMenu request success', result)
            return result
        } else {
            console.error('getMenu request failed', response)
        }
        return []
    },
    getTimeslots: async (venueId) => {
        console.log('getTimeslots request started')
        const response = await _fetch(`${BASE_URL}/zonal/get-timeslots?venueId=${venueId}`)
        if (response.status === 200) {
            const result = await response.json()
            console.log('getTimeslots request success', result)
            return result
        } else {
            console.error('getTimeslots request failed', response)
        }
        return []
    },
    getOutstandingOnplOrders: async () => {
        console.log('getOutstandingOnplOrders request started')
        const response = await _fetch(`${BASE_URL}/orderpay/list-outstanding-onpl-orders`)
        if (response.status === 200) {
            const result = await response.json()
            console.log('getOutstandingOnplOrders request success', result)
            return result
        } else {
            console.error('getOutstandingOnplOrders request failed', response)
        }
        return []
    },
    getOrder: async (orderId) => {
        console.log('getOrder request started')
        const response = await _fetch(`${BASE_URL}/orderpay/get-order?id=${orderId}`)
        if (response.status === 200) {
            const result = await response.json()
            console.log('getOrder request success', result)
            return result[0]
        } else {
            console.error('getOrder request failed', response)
        }
        return null
    },
    getVenue: async (venueId) => {
        console.log('getVenue request started')
        const response = await _fetch(`${BASE_URL}/orderpay/get-venue?id=${venueId}`)
        if (response.status === 200) {
            const result = await response.json()
            console.log('getVenue request success', result)
            return result
        } else {
            console.error('getVenue request failed', response)
        }
        return null
    },
    getRecommendations: async (venueId, numberOfDaysToConsiderForRecs = 28) => {
        console.log('getRecommendations request started')
        let url = `${BASE_URL}/orderpay/product-recommendations?id=${venueId}`
        if (numberOfDaysToConsiderForRecs) {
            url += `&d=${numberOfDaysToConsiderForRecs}`
        }
        const response = await _fetch(url)
        if (response.status === 200) {
            const result = await response.json()
            console.log('getRecommendations request success', result)
            return result
        } else {
            console.error('getRecommendations request failed', response)
        }
        return null
    },
}

export default api
