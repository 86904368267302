import React from 'react'
import {Accordion, AccordionDetails, AccordionSummary, makeStyles, Typography} from "@material-ui/core";
import {ExpandMore} from "@material-ui/icons";

const useStyles = makeStyles({
    accordionDetails: {
        display: 'block'
    },
    displayName: {
        display: 'inline-block',
        width: '30%'
    },
    description: {
        display: 'inline-block',
        width: '50%',
        paddingRight: 25
    },
    mayStock: {
        display: 'inline-block',
        width: '10%'
    },
    isOutOfStock: {
        display: 'inline-block',
        width: '10%'
    },
    portionContainer: {
        marginBottom: 10,
        borderBottom: '1px solid #eee',
        width: 500
    },
    portionItemText: {
        display: 'inline-block',
        width: '75%',
    },
    portionItemTextPrice: {
        display: 'inline-block',
        width: '25%'
    },
    productAdditionalDataSection: {
        margin: '20px 0 10px',
    },
    productChoiceSection: {
        marginLeft: 30
    },
    productChoiceOptionsSection: {
        marginLeft: 30,
    },
    productChoiceOption: {
        marginBottom: 10,
        borderBottom: '1px solid #eee',
        width: 850,
    },
    choiceItemText: {
        display: 'inline-block',
        width: '80%',
    },
    choiceItemTextPrice: {
        display: 'inline-block',
        width: '10%'
    },
})
const ZonalProduct = ({ item, product, choiceGroups, choiceProducts }) => {
    const classes = useStyles()

    let description = ''
    if (product.displayRecords && product.displayRecords[0]) {
        ({ description} = product.displayRecords[0])
    }

    return (
        <Accordion key={item}>
            <AccordionSummary expandIcon={<ExpandMore />}>
                <Typography className={classes.displayName}>
                    {item.product.displayName} / {product.eposName.toUpperCase()}
                    <br />
                    ({item.product.productId})
                </Typography>
                <Typography className={classes.description}>{description}</Typography>
                <Typography className={classes.mayStock}>MayStock<br />{(!!item.product.mayStock).toString()}</Typography>
                <Typography className={classes.isOutOfStock}>IsOutOfStock<br />{(!!product.isOutOfStock).toString()}</Typography>

            </AccordionSummary>
            <AccordionDetails className={classes.accordionDetails}>
                {(product.portions || []).length ? (
                    <Typography className={classes.productAdditionalDataSection}><strong>Portions & Prices</strong></Typography>
                ) : null}
                {(product.portions || []).map((portion) => (
                    <div className={classes.portionContainer} key={`${product.id}_${portion.id}`}>
                        <Typography className={classes.portionItemText}>
                            {portion.portion_name} ({portion.name})
                        </Typography>
                        <Typography className={classes.portionItemTextPrice}>£{portion.price ? portion.price.toFixed('2') : '-'}</Typography>
                    </div>
                ))}

                {(item.product.choices || []).length ? (
                    <Typography className={classes.productAdditionalDataSection}><strong>Product Choices</strong></Typography>
                ) : null}
                {(item.product.choices || []).map((choice) => {
                    const choiceData = choiceGroups.find((c) => c.id === choice.productId)
                    return (
                        <div key={choiceData.id} className={classes.productChoiceSection}>
                            <Typography>
                                <strong>{choiceData.name} ({choiceData.displayRecords[0].name})</strong>
                            </Typography>
                            <div className={classes.productChoiceOptionsSection}>
                                {(choiceData.choices || []).map((option) => {
                                    const choiceProduct = choiceProducts.find((p) => p.id === option.productId);
                                    const displayRecordId = option.displayRecords[0].productDisplayRecordId
                                    const productDisplayRecord = choiceProduct.displayRecords.find((dr) => dr.id === displayRecordId)
                                    console.log(option, choiceProduct)
                                    return (
                                        <div key={choiceProduct.id} className={classes.productChoiceOption}>
                                            <Typography className={classes.choiceItemText}>
                                                {choiceProduct.eposName} ({productDisplayRecord.name})
                                            </Typography>
                                            <Typography className={classes.choiceItemTextPrice}>
                                                £{(((choiceProduct.portions || [])[0] || {}).price || 0).toFixed('2')}
                                            </Typography>
                                            <Typography className={classes.isOutOfStock}>
                                                {(!!choiceProduct.isOutOfStock) ? 'OutOfStock' : 'InStock'}
                                            </Typography>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    )
                })}
            </AccordionDetails>
        </Accordion>
    )
}

export default ZonalProduct
