import React, { useState, useEffect } from 'react'
import {AppBar, Tab, Tabs, ThemeProvider, createMuiTheme} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import blue from '@material-ui/core/colors/blue'
import grey from '@material-ui/core/colors/grey'
import { HashRouter as Router, useLocation } from 'react-router-dom'
import TabPanel from './components/tab-panel/tab-panel'
import ZonalDebugger from './components/pages/zonal-debugger/zonal-debugger'
import OrderPayDebugger from './components/pages/orderpay-debugger/orderpay-debugger'
import OnplDebugger from './components/pages/onpl-debugger/onpl-debugger'
import OrderViewer from './components/pages/order-viewer/order-viewer'
import RecommendationsEngine from './components/pages/recommendations-engine/recommendations-engine'
import IntegrationsPage from './components/pages/integrations/integrations'
import LoginPage from './components/pages/login/login'
import jwt_decode from "jwt-decode";

const ONE_MINUTE = 60000

const useStyles = makeStyles({
    root: {
        border: 0,
        color: 'white',
        height: 48,
        left: 0,
        top: 0,
        padding: 0,
    },
    tabs: {
        "& .MuiTabs-indicator": {
            backgroundColor: '#55c'
        }
    }
})

const TabBar = (props) => {
    const { selectedTabIndex, handleChange, classes } = props
    const { pathname } = useLocation()

    useEffect(() => {
        if (pathname.startsWith('/zonal')) {
            handleChange(null, 1)
        }
        if (pathname.startsWith('/onpl')) {
            handleChange(null, 2)
        }
        if (pathname.startsWith('/order')) {
            handleChange(null, 3)
        }
        if (pathname.startsWith('/re')) {
            handleChange(null, 4)
        }
        if (pathname.startsWith('/integrations')) {
            handleChange(null, 5)
        }
    }, [pathname])

    return (
        <Tabs
            indicatorColor="primary"
            className={classes.tabs}
            value={selectedTabIndex}
            onChange={handleChange}
            aria-label="simple tabs example"
        >
            <Tab component="a" href="/#/" label="OrderPay Debugger" />
            <Tab component="a" href="/#/zonal" label="Zonal Debugger" />
            <Tab component="a" href="/#/onpl" label="ONPL Debugger" />
            <Tab component="a" href="/#/order" label="Order Viewer" />
            <Tab component="a" href="/#/re" label="Rec. Eng." />
            <Tab component="a" href="/#/integrations" label="Integrations" />
        </Tabs>
    )
}

const App = () => {
    const theme = createMuiTheme({
        palette: {
            primary: blue,
        },
        typography: {
            allVariants: {
                color: grey.A400
            }
        }
    });

    const [selectedTabIndex, setSelectedTabIndex] = useState(0)
    useEffect(() => {
        if (window.location.hash.startsWith('#/zonal')) {
            setSelectedTabIndex( 1)
        }
        if (window.location.hash.startsWith('#/onpl')) {
            setSelectedTabIndex( 2)
        }
        if (window.location.hash.startsWith('#/order')) {
            setSelectedTabIndex( 3)
        }
        if (window.location.hash.startsWith('#/re')) {
            setSelectedTabIndex( 4)
        }
        if (window.location.hash.startsWith('#/integrations')) {
            setSelectedTabIndex( 5)
        }
    }, [])

    const handleChange = (event, newValue) => {
        setSelectedTabIndex(newValue);
    };

    const classes = useStyles()

    const token = window.localStorage.getItem('accessToken')
    let isLoggedIn = !!token

    if (token) {
        const decodedToken = jwt_decode(token);
        const msUntilExpiry = ((decodedToken.exp * 1000) - new Date().getTime())
        if (msUntilExpiry >= (ONE_MINUTE * 5)) {
            isLoggedIn = true
            setTimeout(() => {
                window.localStorage.removeItem('accessToken')
                window.localStorage.setItem('at_expired', 1)
                window.location.reload()
            }, msUntilExpiry)
        } else {
            window.localStorage.removeItem('accessToken')
        }
    }

    if (!isLoggedIn) {
        return (<LoginPage />)
    }

    return (
        <ThemeProvider theme={theme}>
            <Router>
                <div className={classes.root}>
                    <AppBar position="static">
                        <TabBar selectedTabIndex={selectedTabIndex} handleChange={handleChange} classes={classes} />
                    </AppBar>
                    <TabPanel value={selectedTabIndex} index={0}>
                        <OrderPayDebugger />
                    </TabPanel>
                    <TabPanel value={selectedTabIndex} index={1}>
                        <ZonalDebugger />
                    </TabPanel>
                    <TabPanel value={selectedTabIndex} index={2}>
                        <OnplDebugger />
                    </TabPanel>
                    <TabPanel value={selectedTabIndex} index={3}>
                        <OrderViewer />
                    </TabPanel>
                    <TabPanel value={selectedTabIndex} index={4}>
                        <RecommendationsEngine />
                    </TabPanel>
                    <TabPanel value={selectedTabIndex} index={5}>
                        <IntegrationsPage />
                    </TabPanel>
                </div>
            </Router>
        </ThemeProvider>
    );
}

export default App;
