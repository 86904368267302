import React, {useEffect} from 'react'
import GoogleLogin from 'react-google-login';
import {makeStyles, Typography} from "@material-ui/core";
import { LockOpen } from '@material-ui/icons';

const setToken = (idToken) => {
    window.localStorage.setItem('accessToken', idToken)
    window.localStorage.removeItem('at_expired')
    window.location.reload()
}

const useStyles = makeStyles({
    cognitoSignin: {
        padding: '10px 20px',
        marginTop: 10,
        display: 'inline-block',
        textDecoration: 'none',
        color: '#111',
        boxShadow: '0px 5px 7px 2px rgb(0 0 0 / 20%)'
    },
    googleSignIn: {
        marginTop: 10,
    },
    pageContainer: {
        textAlign: 'center',
        margin: '150px 0'
    },
    expiryWarning: {
        color: '#f00',
        marginBottom: 30
    },
    spacer: {
        marginTop: 100
    },
    signInIcon: {
        top: 5,
        position: 'relative'
    }
})

const LoginPage = () => {
    const classes = useStyles()

    useEffect(() => {
        const searchParams = new URLSearchParams(window.location.hash.substr(1))
        const idToken = searchParams.get('id_token')
        if (idToken) {
            window.location.hash = '#'
            setToken(idToken)
        }
    }, [])

    const didExpire = window.localStorage.getItem('at_expired')
    return (
        <div className={classes.pageContainer}>

            {didExpire ? (
                <Typography className={classes.expiryWarning}>
                    Your session has expired. Please log in again.
                </Typography>
            ): null}

            <Typography>
                Sign in with your OrderPay Google Account
            </Typography>
            <GoogleLogin
                className={classes.googleSignIn}
                clientId="218224222038-sb7qj6crbbhilecrumcu5ps8adsu5f5s.apps.googleusercontent.com"
                buttonText="Login"
                onSuccess={(response) => setToken(response.getAuthResponse().id_token)}
                onFailure={(response) => setToken(response.getAuthResponse().id_token)}
                cookiePolicy={'single_host_origin'}
            />

            <div className={classes.spacer} />

            <Typography>Sign in with a username & password</Typography>
                <Typography>
                <a
                    className={classes.cognitoSignin}
                    href={`https://op-support-debugger-alpha.auth.eu-west-2.amazoncognito.com/login?response_type=token&client_id=3n9pahdqfcr28a42eqh7uqcuc6&scope=email+openid&redirect_uri=${window.location.origin}/`}
                >
                    <LockOpen className={classes.signInIcon} /> Sign In
                </a>
            </Typography>
        </div>
    );
}

export default LoginPage
