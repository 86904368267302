import React from 'react';
import {
    TableCell,
    TableRow,
    Typography,
    Collapse,
    IconButton,
} from '@material-ui/core'
import { KeyboardArrowDown, KeyboardArrowUp } from '@material-ui/icons'
import { makeStyles } from '@material-ui/core/styles'
import ZonalMenuDetails from '../zonal-menu-details/zonal-menus-details'

const useStyles = makeStyles({
    hoverableRow: {
        cursor: 'pointer',
        '&:nth-child(4n + 1)': {
            background: '#eee'
        },
        '&:hover': {
            background: '#ddd !important'
        }
    },
    expandedRowSpacer: {
        height: 15
    }
})

const ZonalMenusRow = ({ venue, menu }) => {
    const [open, setOpen] = React.useState(false);

    const classes = useStyles()

    return (
        <>
            <TableRow className={classes.hoverableRow} onClick={() => setOpen(!open)}>
                <TableCell><Typography>{menu.id}</Typography></TableCell>
                <TableCell><Typography>{menu.name}</Typography></TableCell>
                <TableCell><Typography>{menu.description}</Typography></TableCell>
                <TableCell><Typography>{menu.canOrder}</Typography></TableCell>
                <TableCell><Typography>{menu.updated}</Typography></TableCell>
                <TableCell><Typography>{menu.version}</Typography></TableCell>
                <TableCell><Typography>{menu.salesAreaId}</Typography></TableCell>
                <TableCell>
                    <IconButton size="small" onClick={() => setOpen(!open)}>
                        {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
                    </IconButton>
                </TableCell>
            </TableRow>
            <TableRow>
                <TableCell style={{paddingBottom: 0, paddingTop: 0}} colSpan={9}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <ZonalMenuDetails venue={venue} menu={menu} />
                        <div className={classes.expandedRowSpacer} />
                    </Collapse>
                </TableCell>
            </TableRow>
        </>
    );
}

export default ZonalMenusRow
