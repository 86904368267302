import React from 'react'
import {Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography} from '@material-ui/core'
import ZonalMenusRow from "../zonal-menus-row/zonal-menus-row";

const ZonalMenusList = ({ venue, menus }) => (
    <TableContainer>
            <Table size="small">
                <TableHead>
                    <TableRow>
                        <TableCell><Typography><strong>ID</strong></Typography></TableCell>
                        <TableCell><Typography><strong>Name</strong></Typography></TableCell>
                        <TableCell><Typography><strong>Description</strong></Typography></TableCell>
                        <TableCell><Typography><strong>CanOrder</strong></Typography></TableCell>
                        <TableCell><Typography><strong>Updated</strong></Typography></TableCell>
                        <TableCell><Typography><strong>VersionID</strong></Typography></TableCell>
                        <TableCell><Typography><strong>Sales Area ID</strong></Typography></TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                        {menus.map((menu) => <ZonalMenusRow key={menu.id} venue={venue} menu={menu} />)}
                </TableBody>
            </Table>
    </TableContainer>
)

export default ZonalMenusList
