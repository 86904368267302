import React, { useState, useEffect } from 'react'
import { Switch, Route } from 'react-router-dom'
import api from '../../../api'
import RecommendationsEngineVenueList from '../recommendations-engine-venue-list/recommendations-engine-venue-list'
import RecommendationsEngineVenueOverview from '../recommendations-engine-venue-oveview/recommendations-engine-venue-oveview'

const RecommendationsEngine = () => {
    const [venues, setVenues] = useState([])

    useEffect(() => {
        const getVenueList = async () => {
            const venues = await api.getVenueList()
            setVenues(venues)
        }
        getVenueList()
    }, [])

    return (
        <>
            <Switch>
                <Route path="/re/:venueId/overview">
                    <>
                        <RecommendationsEngineVenueOverview />
                    </>
                </Route>
                <Route path="/re">
                    <>
                        <RecommendationsEngineVenueList venues={venues} />
                    </>
                </Route>
            </Switch>
        </>
    )
}

export default RecommendationsEngine

